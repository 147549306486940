import { FC, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { UserEventModel } from '@/lib/models/user-event/user-event.model';
import Text from '@/components/text';
import NoneChip from '@/components/chip/none-chip';
import Chip from '@/components/chip';
import {
  getDataCategoryLabel,
  UserEventDataCategory,
} from '@/lib/models/user-event/data-categories';
import AccordionButton from '@/components/accordion-button';
import RiskChip from '@/components/chip/risk-chip';
import { RiskLevel } from '@/lib/models/user-event';

interface Props {
  userEvent: UserEventModel;
}

const RiskRow: FC<{ label: string; level?: RiskLevel; ChipEl?: ReactNode }> = ({
  label,
  level,
  ChipEl,
}) => {
  const hasLevel = !!level;
  const hasChip = !!ChipEl;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Text>{label}</Text>
      {hasChip && ChipEl}
      {!hasChip && (
        <>
          {hasLevel && <RiskChip value={level} size="small" />}
          {!hasLevel && <NoneChip notAvailable />}
        </>
      )}
    </Stack>
  );
};

const CategoryChips: FC<{ categories: string[] }> = ({ categories }) => {
  const hasKnownCategories = !!categories.filter((category) => category !== 'Unknown').length;

  if (!hasKnownCategories) {
    return <NoneChip notAvailable />;
  }

  return (
    <Stack direction="row" gap={0.5} maxWidth="60%" flexWrap="wrap" justifyContent="flex-end">
      {categories.map((category) => {
        if (category === 'Unknown') return null;
        return (
          <Chip
            key={category}
            label={getDataCategoryLabel(category as UserEventDataCategory)}
            color="grey"
            size="small"
          />
        );
      })}
    </Stack>
  );
};

const RiskCard: FC<Props> = ({ userEvent }) => {
  const { riskAssessment } = userEvent;

  if (!riskAssessment) {
    return (
      <Box>
        <NoneChip notAvailable />
      </Box>
    );
  }

  const { input, output } = riskAssessment;

  return (
    <Box>
      <Box mb={1}>
        <AccordionButton title={<Text bold>Input Analysis</Text>}>
          <Stack gap={2.5} mt={2}>
            <RiskRow label="Intent Risk" level={input.intentRisk} />
            <RiskRow label="Data Sensitivity" level={input.dataSensitivity} />
            <RiskRow label="Service Risk" level={input.aiProviderRisk} />
            <RiskRow
              label="Data Categories"
              ChipEl={<CategoryChips categories={input.dataCategories || []} />}
            />
          </Stack>
        </AccordionButton>
      </Box>

      <AccordionButton title={<Text bold>Output Analysis</Text>}>
        <Stack gap={2.5} mt={2}>
          <RiskRow label="Content Sensitivity" level={output.anticipatedContentSensitivity} />
          <RiskRow label="Compliance Risk" level={output.complianceRiskAssessment} />
          <RiskRow label="Harmful Content Risk" level={output.harmfulContentRisk} />
          <RiskRow label="Bias and Fairness Risk" level={output.biasAndFairnessRisk} />

          <RiskRow
            label="Data Categories"
            ChipEl={<CategoryChips categories={output.dataCategories || []} />}
          />
        </Stack>
      </AccordionButton>
    </Box>
  );
};

export default RiskCard;
