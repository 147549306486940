import { FC, useCallback, useRef, useState } from 'react';
import Page, { PageProps } from '@/components/page';
import DataTable, { DataTableInstance } from '@/components/data-table';
import { DtColumn, DtSort } from '@/components/data-table/helpers';
import StatusChip, { ChipStatus } from '@/components/chip/status-chip';
import { getProviders } from '@/lib/services/context-provider.service';
import { getTypedContextProvider } from '@/lib/models/context-provider';
import OpenDrawerButton from '@/components/drawers/open-drawer-button';
import { Box } from '@mui/material';
import useDrawer from '@/hooks/use-drawer';
import { QueryKey } from '@/lib/query-client';
import Button from '@/components/button';
import PageHeader, { PageBlurb } from '@/components/page/header';
import Tooltip from '@/components/tooltip';
import DomSize from '@/components/dom-size';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const columns: DtColumn[] = [
  { name: 'name', label: 'Name', sortable: true },
  { name: 'type', label: 'Type', sortable: true },
  { name: 'status', label: 'Status', sortable: true },
  { name: 'action', label: '', flex: 0.5, sortable: false },
];

const ContextProvidersPage: FC<PageProps> = () => {
  const { openDrawer, DrawerEl } = useDrawer('context-provider');
  const [selectedRowId, setSelectedRowId] = useState('');
  const { canChangeContextProviders, getTooltip, loading: featureLoading } = useFeature();

  const dtRef = useRef<DataTableInstance>(null);

  const handleRefresh = () => {
    dtRef.current?.refresh();
  };

  const handleOpenDrawer = useCallback(
    (providerId?: string) => {
      openDrawer({
        id: providerId || null,
        onClose: () => {
          setSelectedRowId('');
        },
        onChange: handleRefresh,
      });
      setSelectedRowId(providerId || '');
    },
    [openDrawer]
  );

  const loadData = useCallback(
    async (page: number, pageSize: number, sort?: DtSort) => {
      const dataset = await getProviders(page, pageSize, sort);
      return {
        ...dataset,
        rows: dataset.rows.map((model) => {
          const { id, name, typeLabel, status } = getTypedContextProvider(model);

          return {
            id,
            name,
            type: typeLabel,
            status: <StatusChip value={status as ChipStatus} size="small" />,
            action: (
              <Box width="100%" display="flex" justifyContent="flex-end">
                <OpenDrawerButton onClick={() => handleOpenDrawer(id!)} />
              </Box>
            ),
          };
        }),
      };
    },
    [handleOpenDrawer]
  );

  if (featureLoading) {
    return null;
  }

  return (
    <Page title="Data Sources">
      <PageHeader>
        <PageBlurb>
          Create new data sources, and view and change settings for existing data sources.
        </PageBlurb>

        <Tooltip title={getTooltip('add-context-provider')} disabled={canChangeContextProviders}>
          <Button
            label="Add Source"
            icon="plus"
            size="small"
            onClick={() => handleOpenDrawer()}
            disabled={!canChangeContextProviders}
          />
        </Tooltip>
      </PageHeader>
      <PageFeatureToast featureId="change-context-provider" can={canChangeContextProviders} />
      <DomSize>
        <DataTable
          queryKey={QueryKey.ContextProvidersDataset}
          columns={columns}
          onLoad={loadData}
          rowHeight={70}
          search={false}
          selectedRowId={selectedRowId}
          ref={dtRef}
          poll={15000}
        />
      </DomSize>
      {DrawerEl}
    </Page>
  );
};

export default ContextProvidersPage;
