import { FC, useEffect } from 'react';
import Page, { PageProps } from '@/components/page';
import Tabs from '@/components/tabs';
import ProfileForm from './profile-form';
import PublicServicesForm from './public-services-form';
import { useQuery } from 'react-query';
import { useParams } from '@tanstack/react-router';
import { getPolicyById } from '@/lib/services/policy.service';
import useApp from '@/hooks/use-app.hook';
import PolicyGroupsForm from './groups-form';
import IntentForm from './intent-form';
import { QueryKey } from '@/lib/query-client';
import ContextProvidersForm from './context-providers-form';

const TabLabels = ['Profile', 'Groups', 'Model Access', 'Data Sources'];

const PolicyViewPage: FC<PageProps> = () => {
  const { policyId } = useParams({ from: '/config/policies/$policyId' });
  const { setPageTitle, isDemo } = useApp();

  const { data: policy } = useQuery([QueryKey.PolicyView, policyId], async () =>
    getPolicyById(policyId)
  );

  useEffect(() => {
    if (policy) {
      setPageTitle(policy.name, 'policy');
    }
  }, [policy, setPageTitle]);

  const compTabLabels = [...TabLabels];
  if (isDemo) {
    compTabLabels.push('Intents');
  }

  return (
    <Page>
      <Tabs
        labels={compTabLabels}
        panels={[
          <ProfileForm policyId={policyId} />,
          <PolicyGroupsForm policyId={policyId} />,
          <PublicServicesForm policyId={policyId} />,
          <ContextProvidersForm policyId={policyId} />,
          <IntentForm policyId={policyId} />,
        ]}
      />
    </Page>
  );
};

export default PolicyViewPage;
