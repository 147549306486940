import NoneChip from '@/components/chip/none-chip';
import StatusChip, { ChipStatus } from '@/components/chip/status-chip';
import ReadonlyView from '@/components/form/readonly-view';
import { ReadonlyField } from '@/components/form/readonly-view/helpers';
import { JsonObject } from '@/lib/helpers';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { getTypedContextProvider, KendraMetrics } from '@/lib/models/context-provider';
import { ContextProviderModel } from '@/lib/models/context-provider/context-provider.model';
import { SharepointConfig } from '@/lib/models/context-provider/sharepoint-context-provider.model';
import { WebcrawlerConfig } from '@/lib/models/context-provider/webcrawler-context-provider.model';
import { FC, useMemo } from 'react';
import KendraMetricsCard from './kendra-metrics-card';
import KendraCrawlList from './kendra-crawl-list';
import { AwsKbConfig } from '@/lib/models/context-provider/aws-kb-context-provider.model';

interface Props {
  provider: ContextProviderModel;
  connector: ConnectorModel | null;
}

export const ContextProviderReadonlyView: FC<Props> = ({ provider, connector }) => {
  const [data, fields] = useMemo(() => {
    let fields: ReadonlyField[] = [
      { label: 'Name', prop: 'name' },
      { label: 'Type', prop: 'type' },
      { label: 'Status', prop: 'status' },
      { label: 'Connector', prop: 'connector' },
    ];

    const { name, type, typeLabel, status, config, metrics } = getTypedContextProvider(provider);

    let data: JsonObject = {
      name,
      type: typeLabel,
      status: <StatusChip value={status as ChipStatus} size="small" />,
      connector: connector?.name || <NoneChip />,
    };

    switch (type) {
      case 'surepath-kendra-webcrawler':
        {
          const { depth, completeUrls } = config as WebcrawlerConfig;

          fields = [
            ...fields,
            { label: 'Crawl Depth', prop: 'depth' },
            { label: 'URLs', prop: 'paths' },
            { label: 'Usage', prop: 'metrics', propType: 'section' },
            { label: '', prop: 'metrics' },
          ];

          data = {
            ...data,
            depth,
            paths: <KendraCrawlList urls={completeUrls} />,
            metrics: <KendraMetricsCard metrics={metrics as KendraMetrics} />,
          };
        }
        break;
      case 'surepath-kendra-sharepoint':
        {
          const { completeUrls } = config as SharepointConfig;

          fields = [
            ...fields,
            { label: 'Sites', prop: 'paths' },
            { label: 'Usage', prop: 'metrics', propType: 'section' },
            { label: '', prop: 'metrics' },
          ];

          data = {
            ...data,
            paths: <KendraCrawlList urls={completeUrls} />,
            metrics: <KendraMetricsCard metrics={metrics as KendraMetrics} />,
          };
        }
        break;
      case 'customer-bedrock-knowledgebase':
        {
          const { knowledgeBaseId, knowledgeBaseName } = config as AwsKbConfig;

          fields = [
            ...fields,
            { label: 'Knowledge Base Id', prop: 'knowledgeBaseId' },
            { label: 'Knowledge Base Name', prop: 'knowledgeBaseName' },
          ];
          data = {
            ...data,
            knowledgeBaseId: knowledgeBaseId || <NoneChip notAvailable />,
            knowledgeBaseName: knowledgeBaseName || <NoneChip notAvailable />,
          };
        }
        break;
    }

    return [data, fields];
  }, [provider, connector?.name]);

  return <ReadonlyView fields={fields} data={data} />;
};
