import Page, { PageProps } from '@/components/page';
import Tabs from '@/components/tabs';
import useApp from '@/hooks/use-app.hook';
import ContextProvidersForm from '@/pages/config/org-policy/context-providers-form';
import OrgPolicyIntentForm from '@/pages/config/org-policy/intent-form';
import OrgPolicyPublicServicesForm from '@/pages/config/org-policy/public-services-form';
import OrgPolicySensitiveDataForm from '@/pages/config/org-policy/sensitive-data-form';
import { FC } from 'react';

const TabLabels = ['Model Access', 'Data Sources', 'Sensitive Data'];

const OrgPolicyPage: FC<PageProps> = () => {
  const { isDemo } = useApp();

  const compTabLabels = [...TabLabels];
  if (isDemo) {
    compTabLabels.push('Intents');
  }

  return (
    <Page title="Default Policy">
      <Tabs
        labels={compTabLabels}
        panels={[
          <OrgPolicyPublicServicesForm />,
          <ContextProvidersForm />,
          <OrgPolicySensitiveDataForm />,
          <OrgPolicyIntentForm />,
        ]}
      />
    </Page>
  );
};

export default OrgPolicyPage;
