import { DEFAULT_PAGE_SIZE, Dataset, DtSort } from '@/components/data-table/helpers';
import {
  deleteOne,
  findAll,
  findAllAndCount,
  findOne,
  getOidParam,
  getOidParams,
  getOrgFilter,
  getSort,
  insertOne,
  patchOne,
  update,
} from './atlas-data-api.service';
import { PolicyModel } from '../models/policy.model';
import { JsonObject } from '../helpers';
import { getGroupsByIds, getGroupsByPolicyId, patchGroup } from './group.service';
import { cloneDeep, flatten } from 'lodash';
import { DeleteResponse, UpdateResponse } from '.';
import { ContextProviderMeta } from '../models/context-provider';
import { getProvidersByIds } from './context-provider.service';

const COLLECTION = 'policies';

export const getGroupPolicies = async (
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  dtSort?: DtSort
  //   dtFilter?: DtFilter
): Promise<Dataset<PolicyModel>> => {
  const sort = getSort(dtSort) || { name: 1 };

  const skip = page * pageSize;
  const filter = getOrgFilter();
  filter.scope = 'group';

  const { documents, total } = await findAllAndCount(COLLECTION, filter, pageSize, skip, sort);

  const rows: PolicyModel[] = documents.map((data) => new PolicyModel(data));

  return {
    page,
    pageSize,
    rows,
    total,
  };
};

export const getPolicyById = async (policyId: string): Promise<PolicyModel | null> => {
  const params = {
    filter: {
      _id: { $oid: policyId },
      ...getOrgFilter(),
    },
  };

  const response = await findOne(COLLECTION, params);

  return response ? new PolicyModel(response) : null;
};

export const getPoliciesyByGroupId = async (groupId: string): Promise<PolicyModel[]> => {
  const params = {
    filter: {
      groups: { $oid: groupId },
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response ? response.map((data) => new PolicyModel(data)) : [];
};

export const getPoliciesWithContextByGroupId = async (
  groupId: string
): Promise<[PolicyModel[], Map<string, ContextProviderMeta>]> => {
  const policies = await getPoliciesyByGroupId(groupId);

  if (!policies.length) {
    return [[], new Map()];
  }

  const providerIds = flatten(
    policies.map(({ contextDataSources }) => contextDataSources?.enabled || [])
  );

  if (!providerIds.length) {
    return [policies, new Map()];
  }

  const providers = await getProvidersByIds(providerIds);

  const contextMeta: Map<string, ContextProviderMeta> = new Map();

  providers.forEach((provider) => {
    contextMeta.set(provider.id!, provider.meta);
  });

  return [policies, contextMeta];
};

export const getOrgPolicy = async (): Promise<PolicyModel | null> => {
  const params = {
    filter: {
      scope: 'org',
      ...getOrgFilter(),
    },
  };
  let response = await findOne(COLLECTION, params);
  // If no org policy exists, create an empty one
  if (!response?._id) {
    const { orgId } = getOrgFilter();
    const blankRecord = {
      orgId,
      name: '',
      description: '',
      scope: 'org',
      publicServices: {
        enabled: [],
        disabled: [],
      },
      contextDataSources: {
        enabled: [],
        disabled: [],
      },
      sensitiveData: {
        detect: false,
        action: 'block',
        entities: [],
      },
      groups: [],
    };
    await insertOne(COLLECTION, blankRecord);
    response = await findOne(COLLECTION, params);
  }
  return response ? new PolicyModel(response) : null;
};

export const getOrgPolicyWithContext = async (): Promise<
  [PolicyModel | null, Map<string, ContextProviderMeta>]
> => {
  const contextMeta: Map<string, ContextProviderMeta> = new Map();
  const policy = await getOrgPolicy();

  if (!policy?.contextDataSources?.enabled?.length) {
    return [policy, new Map()];
  }

  const providerIds = policy?.contextDataSources?.enabled;
  const providers = await getProvidersByIds(providerIds);

  providers.forEach((provider) => {
    contextMeta.set(provider.id!, provider.meta);
  });

  return [policy, contextMeta];
};

export const createGroupPolicy = async (policy: PolicyModel): Promise<string | null> => {
  const { orgId } = getOrgFilter();

  const document = {
    ...policy._props,
    scope: 'group',
    orgId,
  };

  return insertOne(COLLECTION, document);
};

export const patchPolicy = async (policyId: string, data: JsonObject): Promise<boolean> => {
  const filter = {
    _id: { $oid: policyId },
    ...getOrgFilter(),
  };

  const patchData = cloneDeep(data);

  /*
   * map string public service, data source, and group ids back to oid objects
   */
  if (patchData.publicServices) {
    const psList = patchData.publicServices as JsonObject;
    if (psList.enabled) {
      psList.enabled = getOidParams(psList.enabled as string[]);
    }
    if (psList.disabled) {
      psList.disabled = getOidParams(psList.disabled as string[]);
    }
    patchData.publicServices = psList;
  }

  if (patchData.contextDataSources) {
    const csList = patchData.contextDataSources as JsonObject;
    if (csList.enabled) {
      csList.enabled = getOidParams(csList.enabled as string[]);
    }
    if (csList.disabled) {
      csList.disabled = getOidParams(csList.disabled as string[]);
    }
    patchData.contextDataSources = csList;
  }

  const hasGroups = Array.isArray(patchData.groups);
  if (hasGroups) {
    patchData.groups = getOidParams(patchData.groups as string[]);
  }

  const response = await patchOne(COLLECTION, filter, patchData);
  const updated = response === null;

  if (updated && hasGroups) {
    syncGroupsToPolicy(new PolicyModel({ id: policyId, groups: data.groups }));
  }

  return response === null;
};

/*
 * Given a policy with a list of applied groups, ensure that any applied group
 * also lists the policy in its array of policies, and any group which is not
 * applied does not list the policy.
 */
export const syncGroupsToPolicy = async (policy: PolicyModel): Promise<boolean> => {
  const { groups, id } = policy;
  const policyId = id!;

  const currentGroups = await getGroupsByPolicyId(policyId);
  const updates: Promise<UpdateResponse>[] = [];

  // list of groups that do have the policies
  currentGroups.forEach(({ id: groupId, policies }) => {
    // this will always return > -1, but ts can't figure that out :-/
    const policyIndex = policies.findIndex((id) => id === policyId);

    // not in the list? remove the policy from the group
    if (!groups.includes(groupId!) && policyIndex > -1) {
      const updatedPolicies = cloneDeep(policies);
      updatedPolicies.splice(policyIndex, 1);
      updates.push(patchGroup(groupId!, { policies: getOidParams(updatedPolicies) }));
    }
  });

  // add the policy to groups which need it
  if (groups.length) {
    const updateGroups = await getGroupsByIds(groups);
    updateGroups.forEach(({ id: groupId, policies }) => {
      if (!policies.includes(policyId)) {
        policies.push(policyId);
        updates.push(patchGroup(groupId!, { policies: getOidParams(policies) }));
      }
    });
  }

  const response = await Promise.all(updates);

  return response === null;
};

export const deletePolicy = async (policyId: string): Promise<DeleteResponse> => {
  const filter = {
    _id: getOidParam(policyId),
    ...getOrgFilter(),
  };

  const deleted = await deleteOne(COLLECTION, filter);

  // remove this policy from all groups
  if (deleted) {
    await syncGroupsToPolicy(new PolicyModel({ id: policyId, groups: [] }));
  }

  return { deleted };
};

export const removeGroupFromPolicies = async (groupId: string): Promise<UpdateResponse> => {
  const groupIdParam = getOidParam(groupId);

  const response = await update(
    COLLECTION,
    { groups: groupIdParam },
    { $pull: { groups: groupIdParam } }
  );

  return { updated: response !== null };
};
