import { FC } from 'react';
import Card from '@/components/card';
import Chip from '@/components/chip';
import PublicServiceButton from '@/components/public-service-button';
import Text from '@/components/text';
import { PolicyMeta } from '@/lib/models/policy.model';
import { PolicyModel } from '@/lib/models/policy.model';
import { PublicServiceMeta } from '@/lib/models/public-service.model';
import { Box, Stack } from '@mui/material';
import { GroupMeta } from '@/lib/models/group.model';
import { ContextProviderMeta } from '@/lib/models/context-provider';
import useAppData from '@/hooks/use-app-data.hook';
import CardHeader from '../card/card-header';
import { Link } from '@tanstack/react-router';
import { AppDataQueryKey } from '@/lib/query-client';

interface Props {
  policy: PolicyMeta | PolicyModel;
  groups?: GroupMeta[];
  contextProviders?: ContextProviderMeta[];
}

const PolicyCard: FC<Props> = ({ policy, groups = [], contextProviders = [] }) => {
  const isOrgPolicy = policy.scope === 'org';
  const { name, scope, publicServices } = policy;
  const isOrg = scope === 'org';
  const hasServices = !!publicServices.enabled.length;
  const hasGroups = !!groups.length;
  const hasContext = !!contextProviders.length;
  const link = isOrgPolicy ? '/config/default-policy' : `/config/policies/${policy.id}`;

  const { data: psList = [] } = useAppData<PublicServiceMeta[]>(AppDataQueryKey.PublicServices);

  return (
    <Card>
      <CardHeader title={isOrg ? 'Organization' : name} icon={isOrg ? 'organization' : 'policy'}>
        <Link to={link}>
          <Text size="small">View Policy</Text>
        </Link>
      </CardHeader>

      <Box>
        <Box mb={2}>
          <Text size="small">Allowed Public Services</Text>
        </Box>
        {!hasServices && (
          <Text size="small" color="grey">
            This policy does not grant access to any public services.
          </Text>
        )}
        {hasServices && (
          <Stack gap={1}>
            {publicServices.enabled.map((psId) => {
              const publicService = psList.find(({ id }) => id === psId);
              if (!publicService) {
                return null;
              }
              return <PublicServiceButton publicService={publicService} key={psId} />;
            })}
          </Stack>
        )}
        {hasContext && (
          <Box>
            <Box mb={1} mt={2}>
              <Text size="small">Assigned Data Sources</Text>
            </Box>
            <Stack direction="row" gap={1}>
              {contextProviders.map(({ id, name }) => {
                return <Chip key={id} label={name} />;
              })}
            </Stack>
          </Box>
        )}
        {!isOrgPolicy && hasGroups && (
          <Box>
            <Box mb={1} mt={2}>
              <Text size="small">Assigned Groups</Text>
            </Box>

            <Stack direction="row" gap={1}>
              {groups.map(({ id, name }) => {
                return <Chip key={id} label={name} />;
              })}
            </Stack>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default PolicyCard;
